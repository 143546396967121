<style lang="scss">
#changepassword {
  .row {
    margin-bottom: 60px;
  }
  button {
    width: 100%;
  }
}
</style>

<template>
  <div id="changepassword">
    <div class="row">
      <div class="col-12">
        <h1>Mein Passwort ändern</h1>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-2">
        <div class="form-element">
          <label for="password1" :class="{ hasvalue: pw1 != '' }">Passwort</label>
          <input class="form-control" type="password" id="password1" v-model="pw1" placeholder="Password" />
        </div>
      </div>
      <div class="col-2">
        <div class="form-element">
          <label for="password2" :class="{ hasvalue: pw2 != '' }">Passwort wiederholen</label>
          <input class="form-control" type="password" id="password2" v-model="pw2" placeholder="Passwort wiederholen" />
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-4">
        <button class="btn-form" @click="onSaveClicked">Speichern</button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AxiosResponse } from 'axios';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingTable from '../../components/LinkedSortingTable.vue';

export default defineComponent({
  components: {
    LinkedSortingTable,
  },
  setup() {
    const store = useStore();
    const pw1 = ref('');
    const pw2 = ref('');

    const onSaveClicked = () => {
      if (pw1.value == '' && pw2.value == '') {
        store.dispatch('addNotification', {
          type: 'error',
          message: 'Passwort darf nicht leer sein!',
        });
        return;
      }
      if (pw1.value != pw2.value) {
        store.dispatch('addNotification', {
          type: 'error',
          message: 'Passwörter stimmen nicht überein!',
        });
        pw1.value = '';
        pw2.value = '';
        return;
      } else {
        store.dispatch('savePassword', pw1.value).then(() => {
          store.dispatch('addNotification', {
            type: 'success',
            lifetime: 4000,
            message: 'Änderung gespeichert',
          });
        });
      }
    };

    return {
      pw1,
      pw2,

      onSaveClicked,
    };
  },
});
</script>
