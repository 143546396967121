<style lang="scss">
.table {
  width: 100%;
  border-spacing: 0;
  padding-top: 10px;
  @media (max-width: 1380px) {
    font-size: 90%;
  }

  tr {
    cursor: pointer;
    transition: 0.2s all;

    td {
      padding: 5px;
      text-align: left;

      img {
        border: 1px solid red;
      }
    }
    &:nth-child(odd) {
      background: #f9f9f9;
    }
    &:hover {
      background-color: #f1f1f1;

      td {
        &:first-child {
          background-color: none;
        }
      }
    }
  }

  th {
    &:first-child {
      margin-top: -1px;
      position: relative;
      top: 0px;
    }
    text-align: left;
    font-family: 'HelveticaNeueLTPro-lt';
    text-transform: uppercase;
    padding: 15px 5px;
    height: 30px !important;
    border-bottom: 1px solid #eee;
    letter-spacing: 1px;
    font-size: 90%;
  }
}

.dived {
  .row {
    cursor: pointer;
    border: 1px solid black;
    margin-bottom: 2px;
    padding: 5px;
    &:hover {
      background-color: #000;
      color: #fff;
    }

    .rowcontent {
      display: flex;

      .label {
        width: 30%;
        font-weight: bold;
      }

      .content {
        margin-left: 5px;
      }
    }
  }
}
</style>

<template>
  <table class="table" v-if="!mobile">
    <th :key="i" v-for="(header, i) in headers">
      {{ header.label }}
    </th>
    <router-link :to="item.link" :key="i" v-for="(item, i) in items" custom v-slot="{ navigate }">
      <tr @click="navigate">
        <td :key="i" v-for="(header, i) in headers">
          <div v-html="item[header.key]" v-if="header.type == 'raw'"></div>
          <span v-else>{{ item[header.key] }}</span>
        </td>
      </tr>
    </router-link>
  </table>
  <div class="dived" v-else>
    <router-link :to="item.link" :key="item['_id']" v-for="item in items" custom v-slot="{ navigate }">
      <div class="row" @click="navigate">
        <div class="rowcontent" :key="header.key" v-for="header in headers">
          <div class="label">{{ header.label }}</div>
          <div class="content">{{ item[header.key] }}</div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      requried: true,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
  },
});
</script>
