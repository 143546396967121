
import { AxiosResponse } from 'axios';
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import LinkedSortingTable from '../../components/LinkedSortingTable.vue';

export default defineComponent({
  components: {
    LinkedSortingTable,
  },
  setup() {
    const store = useStore();
    const pw1 = ref('');
    const pw2 = ref('');

    const onSaveClicked = () => {
      if (pw1.value == '' && pw2.value == '') {
        store.dispatch('addNotification', {
          type: 'error',
          message: 'Passwort darf nicht leer sein!',
        });
        return;
      }
      if (pw1.value != pw2.value) {
        store.dispatch('addNotification', {
          type: 'error',
          message: 'Passwörter stimmen nicht überein!',
        });
        pw1.value = '';
        pw2.value = '';
        return;
      } else {
        store.dispatch('savePassword', pw1.value).then(() => {
          store.dispatch('addNotification', {
            type: 'success',
            lifetime: 4000,
            message: 'Änderung gespeichert',
          });
        });
      }
    };

    return {
      pw1,
      pw2,

      onSaveClicked,
    };
  },
});
